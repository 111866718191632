import HTTP from "@/http";

export function getYuYan(params = {}) {
  return HTTP({
    url: "api/yuyan",
    method: "GET",
    params,
  });
}

export function getYuYanBao(params = {}) {
  return HTTP({
    url: "api/yuyanbao",
    method: "GET",
    params,
  });
}
