<template>
  <div id="app">
    <router-view />
    <a id="scroll-top" class="scroll-top" href="#top" title="Top" role="button">
      <i class="w-icon-angle-up"></i>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
        <circle
          id="progress-indicator"
          fill="transparent"
          stroke="#000000"
          stroke-miterlimit="10"
          cx="35"
          cy="35"
          r="34"
          style="stroke-dasharray: 16.4198, 400"
        ></circle>
      </svg>
    </a>
  </div>
</template>

<script>
import Vue from "vue";
import { getSheZhi } from "@/api/shezhi";
import { getYuYan, getYuYanBao } from "@/api/yuyan";
export default {
  mounted() {
    let jindu = 0;
    getSheZhi()
      .then((data) => {
        if (data.status === "SUCCESSED") {
          let shezhi = {};
          for (let i = 0, len = data.data.length; i < len; i += 1) {
            shezhi[data.data[i].jian] = this.duoyuyan(data.data[i], "zhi");
          }
          Vue.prototype.$shezhi = shezhi;
          localStorage.setItem("shezhi", JSON.stringify(shezhi));
        }
      })
      .finally(() => (jindu += 1));
    getSheZhi({ mitu: "mitu" })
      .then((data) => {
        if (data.status === "SUCCESSED") {
          let shezhi = {};
          for (let i = 0, len = data.data.length; i < len; i += 1) {
            shezhi[data.data[i].jian] =
              data.data[i]["zhi_" + this.$yuyan] || data.data[i].zhi;
          }
          Vue.prototype.$shezhimitu = shezhi;
          localStorage.setItem("shezhimitu", JSON.stringify(shezhi));
        }
      })
      .finally(() => (jindu += 1));
    getYuYan({ zhuangtai: 1 })
      .then((data) => {
        if ((data.status = "SUCCESSED")) {
          Vue.prototype.$yuyans = data.data;
          localStorage.setItem("yuyans", JSON.stringify(data.data));
        }
      })
      .finally(() => (jindu += 1));
    getYuYanBao()
      .then((data) => {
        if (data.status === "SUCCESSED") {
          let yuyanbao = {};
          for (let i = 0, len = data.data.length; i < len; i += 1) {
            yuyanbao[data.data[i].jian] = this.duoyuyan(data.data[i], "zhi");
          }
          Vue.prototype.$yuyanbao = yuyanbao;
          localStorage.setItem("yuyanbao", JSON.stringify(yuyanbao));
        }
      })
      .finally(() => (jindu += 1));
    let timer = setInterval(() => {
      if (jindu === 4) clearInterval(timer);
      if (jindu === 4 && !localStorage.getItem("chushihua")) {
        localStorage.setItem("chushihua", 1);
        this.$router.go(0);
      }
    }, 100);
    window.addEventListener("resize", () => {
      this.$store.commit("set_width");
    });
  },
};
</script>

<style>
*::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

.hang {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.hang-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hang-3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.hang-4 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.hangscroll {
  white-space: nowrap;
  overflow: scroll;
}

.hangscroll::-webkit-scrollbar {
  display: none;
}

.pointer {
  cursor: pointer;
}

.text-align-left {
  text-align: left;
}

.grid {
  display: grid;
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
  grid-auto-flow: dense;
  gap: 20px;
}

.grid-item-wrapper {
  width: 322px;
  box-sizing: border-box;
  justify-self: center;
}

.grid-item {
  width: 322px;
  height: 322px;
  background: rgb(255, 255, 255);
  transition: all 0.4s ease 0s;
  box-shadow: rgb(229 228 228) 0px 15px 40px 0px;
  cursor: pointer;
}

.grid-item-image {
  width: 100%;
  height: 70%;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.grid-item-content {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: rgb(49, 49, 49);
  text-transform: uppercase;
  overflow-y: scroll;
}
</style>
