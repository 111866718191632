import Vue from "vue";
import Swal from "sweetalert2";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Kong from "@/components/Kong.vue";
import { YUYAN, TUPIANBASE, TUPIANMOREN, TUPIANMORENLOGO } from "./config";

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component("Kong", Kong);

Vue.prototype.$yuyan = localStorage.getItem("yuyan") || YUYAN;
Vue.prototype.$yuyans = JSON.parse(localStorage.getItem("yuyans") || "[]");
Vue.prototype.$yuyanbao = JSON.parse(localStorage.getItem("yuyanbao") || "{}");
Vue.prototype.$tupianbase = TUPIANBASE;
Vue.prototype.$tupianmoren = TUPIANMOREN;
Vue.prototype.$tupianmorenlogo = TUPIANMORENLOGO;
Vue.prototype.$shezhi = JSON.parse(localStorage.getItem("shezhi") || "{}");
Vue.prototype.$shezhimitu = JSON.parse(localStorage.getItem("shezhimitu") || '{}');


Vue.prototype.duoyuyan = (data, key = "mingcheng") =>
  data[key + "_" + Vue.prototype.$yuyan] || data[key];
Vue.prototype.toast = ({
  title = "test",
  icon = "success",
  timer = 1000,
  timerProgressBar = true,
  position = "top-end",
  showConfirmButton = false,
}) => {
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon,
    title,
  });
};
Vue.prototype.toast_http = ({
  status = "SUCCESSED",
  timer = 1000,
  timerProgressBar = true,
  position = "top-end",
  showConfirmButton = false,
}) => {
  const yuyan = localStorage.getItem("yuyan") || YUYAN;
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const chenggong =
    yuyan === "cn"
      ? "请求成功"
      : yuyan === "fr"
        ? "Demande réussie"
        : "Successful request";
  const chucuo =
    yuyan === "cn"
      ? "请求出错"
      : yuyan === "fr"
        ? "Erreur de demande"
        : "Request error";
  Toast.fire({
    icon: status === "SUCCESSED" ? "success" : "warning",
    title: status === "SUCCESSED" ? chenggong : chucuo,
  });
};
Vue.prototype.toast_success = ({
  title = "test",
  icon = "success",
  timer = 1000,
  position = "top-end",
  showConfirmButton = false,
}) => {
  Swal.fire({
    position,
    icon,
    title,
    showConfirmButton,
    timer
  })
};
Vue.prototype.format_date = (date, type) => {
  let d = new Date(date);
  const YY = d.getFullYear();
  const MM = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const DD = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const HH = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const mm = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  const SS = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
  const yuyan = localStorage.getItem("yuyan") || YUYAN;
  return yuyan === "fr"
    ? `${DD}-${MM}-${YY}${type === 1 ? " " + HH + ":" + mm + ":" + SS : ""}`
    : `${YY}-${MM}-${DD}${type === 1 ? " " + HH + ":" + mm + ":" + SS : ""}`;
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
