export default {
  width: window.innerWidth,
  login: parseInt(localStorage.getItem("login") || "0"),
  yonghu: JSON.parse(localStorage.getItem("yonghu") || "{}"),
  cart: JSON.parse(
    localStorage.getItem("cart") ||
    JSON.stringify({
      total: 0,
      count: 0,
      products: [],
    }),
  ),
};
