import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../Layout.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "Index",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/index.vue"),
      },
      {
        path: "/cart",
        name: "Cart",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/Cart.vue"),
      },
      {
        path: "/aboutus",
        name: "AboutUs",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/AboutUs.vue"),
      },
      {
        path: "/contactus",
        name: "ContactUs",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/ContactUs.vue"),
      },
      {
        path: "/product",
        name: "Product",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/Product.vue"),
      },
      {
        path: "/products",
        name: "Products",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/Products.vue"),
      },
      {
        path: "/boutiques",
        name: "Boutiques",
        component: () =>
          import(/* webpackChunkName: "yxl"*/ "@/views/Boutiques.vue"),
      },
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Login.vue"),
      },
      {
        path: "/service",
        name: "Service",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Service.vue"),
      },
      {
        path: "/servicecommande",
        name: "ServiceCommande",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/ServiceCommande.vue"),
      },
      {
        path: "/receipt",
        name: "Receitp",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Receipt.vue"),
        meta: {
          login: true,
        },
      },
      {
        path: "/account",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Account.vue"),
        meta: {
          login: true,
        },
      },
      {
        path: "/blog",
        name: "Blog",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Blog.vue"),
      },
      {
        path: "/blogdetail",
        name: "BlogDetail",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/BlogDetail.vue"),
      },
      {
        path: "/cgv",
        name: "CGV",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/CGV.vue"),
      },
      {
        path: "/mentionlegal",
        name: "MentionLegal",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/MentionLegal.vue"),
      },
      {
        path: "/search",
        name: "Search",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Search.vue"),
      },

      {
        path: "/boutique",
        name: "Boutique",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Boutique.vue"),
      },
      {
        path: "/promotions",
        name: "Promotions",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/Promotions.vue"),
      },
      {
        path: "/paiementsecurise",
        name: "PaiementSecurise",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/PaiementSecurise.vue"),
      },
      {
        path: "/modelivraison",
        name: "ModeLivraison",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/ModeLivraison.vue"),
      },
      {
        path: "/demanderetour",
        name: "DemanderRetour",
        component: () =>
          import(/* webpackChunkName: "yxl" */ "@/views/DemanderRetour.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "Page404",
    component: () =>
      import(/* webpackChunkName: "yxl" */ "@/views/Page404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior () {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  const route = router.getRoutes();
  const token = localStorage.getItem("token");
  const tokenexp = localStorage.getItem("tokenexp");
  if (route.findIndex((i) => i.name === to.name) === -1) {
    next("/404");
  } else if (!to.meta.login) {
    next();
  } else if (token && new Date().getTime() < parseInt(tokenexp)) {
    next();
  } else if (to.path === "/login") next();
  else {
    next(`/login?redirect=${to.path}`);
  }
});

export default router;
