import Axios from "axios";
import { DOMAIN, TIMEOUT } from "./config";

const http = Axios.create({
  baseURL: DOMAIN,
  withCredentials: true,
  timeout: TIMEOUT,
});

/* 请求拦截器 */
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const shezhi = config;
    if (token) {
      shezhi.headers.common.Authorization = `Bearer ${token}`;
    }
    return shezhi;
  },
  (error) => Promise.reject(error),
);

/* 响应拦截器 */
http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log(`error: ${error}`);
    if (/status code 401/.test(error)) {
      localStorage.setItem("tokenexp", false);
      // window.location.reload();
    }
    return Promise.reject(error);
  },
);

export function all(request, callback, complete) {
  Axios.all(request)
    .then(
      Axios.spread(
        (d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13, d14, d15) => {
          callback(
            d1,
            d2,
            d3,
            d4,
            d5,
            d6,
            d7,
            d8,
            d9,
            d10,
            d11,
            d12,
            d13,
            d14,
            d15,
          );
        },
      ),
    )
    .finally(complete);
  return 0;
}

export default http;
