<template>
  <div class="error-404">
    <div class="error-404-container">
      <div class="error-404-banner">
        <figure>
          <img src="../../public/assets/kong.webp" width="260" height="260" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    plus() {
      this.$emit("tap");
    },
  },
};
</script>

<style scoped>
.error-404 {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-404-container {
  max-width: 1280px;
}

.error-404-banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1rem;
  overflow: hidden;
}

.error-404 figure {
  margin: 0 auto;
  max-width: 82rem;
}

.error-404-banner-content {
  margin-top: 5.5rem;
  text-align: center;
}

.error-404-banner-title {
  margin-bottom: 0.8rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #333;
  letter-spacing: 0;
  font-size: 30px;
}

.error-404-btn {
  border-radius: 3px;
  color: #fff;
  border-color: #333;
  background-color: #333;
  display: inline-block;
  outline: 0;
  font-weight: 600;
  font-size: 14px;
  padding: 0.93em 1.98em;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    box-shadow 0.3s, -webkit-box-shadow 0.3s;
  white-space: nowrap;
  cursor: pointer;
}
</style>
