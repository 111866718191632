<template>
  <div>
    <my-header></my-header>

    <div style="min-height: 100vh">
      <zoom-center-transition :duration="400" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>
    <my-footer></my-footer>
    <tabbar></tabbar>
    <mobile-menu></mobile-menu>
  </div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";
import MyHeader from "@/components/MyHeader.vue";
import MyFooter from "@/components/MyFooter.vue";
import Tabbar from "@/components/Tabbar.vue";
import MobileMenu from "@/components/MobileMenu.vue";

export default {
  name: "Layout",
  data: () => ({}),
  mounted() {},
  methods: {},
  components: { MyHeader, MyFooter, Tabbar, MobileMenu, ZoomCenterTransition },
};
</script>

<style></style>
