<template>
  <footer class="footer" style="padding-bottom: 60px">
    <div class="footer-newsletter bg-primary pt-6 pb-6">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-xl-5 col-lg-6">
            <div class="icon-box icon-box-side text-white">
              <div class="icon-box-icon d-inline-flex">
                <i class="w-icon-envelop3"></i>
              </div>
              <div class="icon-box-content">
                <h4 class="icon-box-title text-white text-uppercase mb-0">
                  {{ $shezhi.dingyuebiaoti }}
                </h4>
                <p class="text-white">
                  {{ $shezhi.dingyuemiaoshu }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-7 col-lg-6 col-md-9 mt-4 mt-lg-0">
            <div
              class="input-wrapper input-wrapper-inline input-wrapper-rounded"
            >
              <input
                id="myfooterdingyue"
                class="form-control mr-2 bg-white"
                :placeholder="$yuyanbao.youxiang"
                v-model="email"
                @keyup.enter="dingyue_http"
              />
              <button
                class="btn btn-dark btn-rounded"
                @click="dingyue_http"
                :disabled="dingyueloading"
              >
                <div class="btn-text">
                  {{ $yuyanbao.dingyue }}
                  <i
                    class="w-icon-store-seo mr-1 xuanzhuan"
                    v-if="dingyueloading"
                  ></i>
                  <i v-else class="w-icon-long-arrow-right"></i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="footer-top">
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="widget widget-about">
              <router-link to="/" class="logo-footer" v-if="$shezhi.logo">
                <img :src="$shezhi.logo" width="144" height="45" />
              </router-link>
              <div class="widget-body">
                <p class="widget-about-title">{{ $shezhi.youxiang }}</p>
                <a :href="'tel:' + $shezhi.dianhua" class="widget-about-call">
                  {{ $shezhi.dianhua }}
                </a>
                <p class="widget-about-desc" v-html="$shezhi.footermiaoshu"></p>
                <div class="social-icons social-icons-colored">
                  <a
                    v-if="$shezhi.facebook"
                    :href="$shezhi.facebook"
                    class="social-icon social-facebook w-icon-facebook"
                  ></a>
                  <a
                    v-if="$shezhi.twitter"
                    :href="$shezhi.twitter"
                    class="social-icon social-twitter w-icon-twitter"
                  ></a>
                  <a
                    v-if="$shezhi.instagram"
                    :href="$shezhi.instagram"
                    class="social-icon social-instagram w-icon-instagram"
                  ></a>
                  <a
                    v-if="$shezhi.youtube"
                    :href="$shezhi.youtube"
                    class="social-icon social-youtube w-icon-youtube"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="widget">
              <h3 class="widget-title">&nbsp;</h3>
              <ul class="widget-body">
                <li>
                  <router-link to="/account">{{
                    $yuyanbao.wodezhanghu
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/aboutus">
                    {{ $yuyanbao.guanyuwomen }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/contackus">
                    {{ $yuyanbao.lianxiwomen }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/mentionlegal">{{
                    $yuyanbao.falushengming
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/cgv">{{
                    $yuyanbao.xiaoshoutiaokuan
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="widget">
              <h4 class="widget-title">{{ $yuyanbao.fuwu }}</h4>
              <ul class="widget-body">
                <li v-for="(item, index) in services" :key="index">
                  <router-link
                    :to="{ path: '/service', query: { ukey: item.ukey } }"
                  >
                    {{ duoyuyan(item) }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="widget">
              <h4 class="widget-title">&nbsp;</h4>
              <ul class="widget-body">
                <li>
                  <router-link to="/paiementsecurise"
                    >Paiement sécurisé</router-link
                  >
                </li>
                <li>
                  <router-link to="modelivraison"
                    >Mode de livraison</router-link
                  >
                </li>
                <li>
                  <router-link to="/demanderetour"
                    >Demander un retour</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="footer-right">
          <p class="copyright">
            Copyright © {{ new Date().getFullYear() }}. All Rights Reserved.
            MITU
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { postDingYue } from "@/api/dingyue";
import { getFuWu } from "@/api/fuwu";

export default {
  data() {
    return {
      email: "",
      dingyueloading: false,
      services: [],
    };
  },
  mounted() {
    this.get_service_http();
  },
  methods: {
    get_service_http() {
      getFuWu().then((data) => {
        if (data.status === "SUCCESSED") this.services = data.data;
      });
    },
    dingyue_http() {
      if (this.dingyueloading) return;
      if (
        this.email === "" ||
        !/^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.email)
      ) {
        this.toast({
          icon: "info",
          title: this.$shezhi.youxiang,
        });
        return;
      }
      this.dingyueloading = true;
      setTimeout(() => {
        postDingYue({
          email: this.email,
        })
          .then((data) => {
            this.toast_http({ status: data.status });
            if (data.status === "SUCCESSED") this.email = "";
          })
          .finally(() => (this.dingyueloading = false));
      }, 500);
    },
  },
};
</script>

<style scoped>
.btn-text {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0 25px;
}

.xuanzhuan {
  position: absolute;
  top: 10px;
  animation: anixuanzhuan 0.8s infinite linear;
}

@keyframes anixuanzhuan {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
