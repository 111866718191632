const set_width = (state) => (state.width = window.innerWidth);

const set_yonghu = (state, yonghu) => {
  state.login = 1;
  state.yonghu = yonghu;
  localStorage.setItem("login", 1);
  localStorage.setItem("yonghu", JSON.stringify(yonghu));
};
const logout = (state) => {
  state.login = 0;
  state.yonghu = {};
  localStorage.setItem("login", 0);
  localStorage.removeItem("yonghu");
};
const add_cart = (state, cartItem) => {
  state.cart.total += cartItem.price;
  state.cart.count += cartItem.count;
  let index = -1;
  for (let i = 0, len = state.cart.products.length; i < len; i += 1) {
    if (state.cart.products[i].id === cartItem.id) {
      index = i;
      break;
    }
  }
  if (index === -1) {
    state.cart.products.push(cartItem);
  } else {
    state.cart.products[index].count += 1;
  }
  localStorage.setItem("cart", JSON.stringify(state.cart));
};
export default {
  set_width,
  set_yonghu,
  logout,
  add_cart,
};
