import HTTP from "@/http";

export function getFuWu(params = {}) {
  return HTTP({
    url: "api/fuwu",
    method: "GET",
    params,
  });
}

export function getFuWuLeiXing(params = {}) {
  return HTTP({
    url: "api/fuwu/leixing",
    method: "GET",
    params,
  });
}

export function getFuWuWenTi(params = {}) {
  return HTTP({
    url: "api/fuwu/wenti",
    method: "GET",
    params,
  });
}
