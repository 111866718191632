class Config {
  YUYAN = "fr";
  DOMAIN = "https://admin.mitu.fr";
  TIMEOUT = 30000;
  TUPIANBASE = this.DOMAIN + "/public/";
  TUPIANMOREN = this.TUPIANBASE + "system/moren.webp";
  TUPIANMORENLOGO = this.TUPIANBASE + "system/logo.webp"
}

module.exports = new Config();
