<template>
  <header class="header">
    <div class="header-top">
      <div class="container">
        <div class="header-left">
          <p class="welcome-msg" style="text-transform: none">
            <span style="margin-right: 20px">
              {{ $shezhi.dianhua }}
            </span>
            <span style="margin-right: 20px">
              {{ $shezhi.youxiang }}
            </span>
            <span>
              {{ $shezhi.yingyeshijian }}
            </span>
          </p>
        </div>
        <div class="header-right pr-0">
          <div class="dropdown">
            <a href="javascript:void(0);" style="text-transform: uppercase">
              <img
                :src="$tupianbase + 'system/' + $yuyan + '.webp'"
                width="14"
                height="8"
                class="dropdown-image"
              />
              {{ $yuyan }}
            </a>
            <div class="dropdown-box">
              <a
                href="javascript:void(0);"
                v-for="(item, index) in $yuyans"
                :key="index"
                style="text-transform: uppercase"
                @click="set_yuyan(item.jian)"
              >
                <img
                  :src="$tupianbase + 'system/' + item.jian + '.webp'"
                  width="14"
                  height="8"
                  class="dropdown-image"
                />
                {{ item.jian }}
              </a>
            </div>
          </div>
          <span class="divider d-lg-show"></span>
          <router-link
            to="/account"
            class="d-lg-show"
            v-if="$store.state.login === 1"
          >
            {{ $yuyanbao.wodezhanghu }}
          </router-link>
          <template v-if="$store.state.login === 0">
            <router-link to="/login" class="d-lg-show">
              <i class="w-icon-account"></i>
              {{ $yuyanbao.denglu }}
            </router-link>
            <span class="delimiter d-lg-show">/</span>
            <router-link to="/login" class="ml-0 d-lg-show">
              {{ $yuyanbao.zhuce }}
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <div class="header-middle">
      <div class="container">
        <div class="header-left mr-md-4">
          <a
            href="#"
            class="mobile-menu-toggle w-icon-hamburger"
            aria-label="menu-toggle"
          ></a>
          <router-link to="/" class="logo ml-lg-0">
            <img :src="$shezhi.logo" width="144" height="45" />
          </router-link>
          <nav class="main-nav">
            <ul class="menu">
              <li class="active">
                <router-link to="/">{{ $yuyanbao.shouye }}</router-link>
              </li>
              <li>
                <a class="d-lg-show">
                  {{ $yuyanbao.fuwu }}
                </a>
                <ul>
                  <li v-for="(item, index) in services" :key="index">
                    <router-link
                      :to="{ path: '/service', query: { ukey: item.ukey } }"
                    >
                      {{ duoyuyan(item) }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/blog" class="d-lg-show">
                  {{ $yuyanbao.boke }}
                </router-link>
              </li>
              <li>
                <router-link to="/aboutus" class="d-lg-show">
                  {{ $yuyanbao.guanyuwomen }}
                </router-link>
              </li>
              <li>
                <router-link to="/contactus" class="d-lg-show">
                  {{ $yuyanbao.lianxiwomen }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="header-right ml-4">
          <div class="header-call d-xs-show d-lg-flex align-items-center">
            <a :href="'tel:' + $shezhi.whatsapp" class="w-icon-call"></a>
            <div class="call-info d-xl-show">
              <h4
                class="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0"
              >
                <a :href="'tel:' + $shezhi.whatsapp" class="text-capitalize">
                  Whatsapp
                </a>
              </h4>
              <a
                :href="'tel:' + $shezhi.whatsapp"
                class="phone-number font-weight-bolder ls-50"
              >
                {{ $shezhi.whatsapp }}
              </a>
            </div>
          </div>
          <!-- <div class="dropdown cart-dropdown mr-0 mr-lg-2">
            <div class="cart-overlay"></div>
            <a href="#" class="cart-toggle label-down link">
              <i class="w-icon-cart">
                <span class="cart-count" v-if="$store.state.cart.count > 0">
                  {{ $store.state.cart.count }}
                </span>
              </i>
              <span class="cart-label">{{ $t("gouwuche") }}</span>
            </a>
            <div class="dropdown-box">
              <div class="products">
                <div class="product product-cart">
                  <div class="product-detail">
                    <router-link to="/product" class="product-name">
                      Beige knitted elas
                      <br />
                      tic runner shoes
                    </router-link>
                    <div class="price-box">
                      <span class="product-quantity">1</span>
                      <span class="product-price">$25.68</span>
                    </div>
                  </div>
                  <figure class="product-media">
                    <router-link to="/product">
                      <img
                        src="assets/images/cart/product-1.jpg"
                        alt="product"
                        height="84"
                        width="94"
                      />
                    </router-link>
                  </figure>
                  <button class="btn btn-link btn-close" aria-label="button">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="product product-cart">
                  <div class="product-detail">
                    <router-link to="/product" class="product-name">
                      Blue utility pina
                      <br />
                      fore denim dress
                    </router-link>
                    <div class="price-box">
                      <span class="product-quantity">1</span>
                      <span class="product-price">$32.99</span>
                    </div>
                  </div>
                  <figure class="product-media">
                    <router-link to="/product">
                      <img
                        src="assets/images/cart/product-2.jpg"
                        alt="product"
                        width="84"
                        height="94"
                      />
                    </router-link>
                  </figure>
                  <button class="btn btn-link btn-close" aria-label="button">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>

              <div class="cart-total">
                <label>Subtotal:</label>
                <span class="price">$58.67</span>
              </div>

              <div class="cart-action">
                <router-link
                  to="/cart"
                  class="btn btn-dark btn-outline btn-rounded"
                >
                  View Cart
                </router-link>
                <router-link to="/checkout" class="btn btn-primary btn-rounded">
                  Checkout
                </router-link>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="header-bottom sticky-content fix-top sticky-header">
      <div class="container">
        <div class="inner-wrap">
          <div class="header-left flex-1">
            <div
              class="dropdown category-dropdown has-border"
              data-visible="true"
            >
              <a
                class="category-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                data-display="static"
                title="Browse Categories"
              >
                <i class="w-icon-category"></i>
                <span>{{ $yuyanbao.quanbufenlei }}</span>
              </a>
              <div class="dropdown-box">
                <ul class="menu vertical-menu category-menu">
                  <li v-for="(item, index) in categorys" :key="index">
                    <router-link
                      :to="{ path: '/products', query: { ukey: item.ukey } }"
                    >
                      {{ duoyuyan(item) }}
                    </router-link>
                    <ul
                      class="megamenu"
                      v-if="item.children && item.children.length > 0"
                    >
                      <li
                        v-for="(item1, index1) in item.children"
                        :key="index1"
                      >
                        <h4
                          class="menu-title"
                          style="cursor: pointer"
                          @click="
                            $router.push({
                              path: '/products',
                              query: {
                                ukey: item1.ukey,
                              },
                            })
                          "
                        >
                          {{ duoyuyan(item1) }}
                        </h4>
                        <hr class="divider" />
                        <ul>
                          <li
                            v-for="(item2, index2) in item1.children"
                            :key="index2"
                          >
                            <router-link
                              :to="{
                                path: '/products',
                                query: { ukey: item2.ukey },
                              }"
                            >
                              {{ duoyuyan(item2) }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <li></li>
                    </ul>
                  </li>
                  <li>
                    <router-link :to="{ path: '/products' }">
                      {{ $yuyanbao.quanbufenlei }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="header-search hs-expanded hs-round d-none d-md-flex input-wrapper mr-4 ml-4"
            >
              <input
                id="myheadersearch"
                type="text"
                class="form-control"
                :placeholder="$yuyanbao.sousuo"
                required
                v-model="search"
                @keyup.enter="search_http"
                style="border-left: 2px solid #336699"
              />
              <button class="btn btn-search" @click="search_http">
                <i class="w-icon-search"></i>
              </button>
            </div>
          </div>
          <div class="header-right pr-0 ml-4">
            <a href="#" class="d-xl-show mr-6">
              <i class="w-icon-map-marker mr-1"></i>
              {{ $yuyanbao.dindanzhuizong }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <MobileMenu></MobileMenu>
  </header>
</template>

<script>
import Vue from "vue";
import MobileMenu from "@/components/MobileMenu.vue";
import { getShangPinFenLei } from "@/api/shanpin_fenlei";
import { getFuWu } from "@/api/fuwu";

export default {
  name: "MyHeader",
  components: {
    MobileMenu,
  },
  data() {
    return {
      categorys: [],
      services: [],
      search: "",
    };
  },
  mounted() {
    this.get_shangpin_fenlei();
    this.get_service_http();
  },
  methods: {
    set_yuyan(yuyan) {
      if (this.$yuyan === yuyan) return;
      Vue.prototype.$yuyan = yuyan;
      localStorage.setItem("yuyan", yuyan);
      window.location.reload();
    },
    get_service_http() {
      getFuWu().then((data) => {
        if (data.status === "SUCCESSED") this.services = data.data;
      });
    },
    get_shangpin_fenlei() {
      getShangPinFenLei().then((data) => {
        if (data.status === "SUCCESSED") this.categorys = data.data;
      });
    },
    search_http() {
      if (this.search === "") return;
      this.$router.push({
        path: "/search",
        query: {
          search: this.search,
        },
      });
      if (this.$route.name === "Search") window.location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-call {
  margin-right: 0;
}

.header-call::after {
  display: none;
}
</style>
